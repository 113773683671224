<template>
  <div style="padding:16px">

    <!-- 头部部分 -->
    <div ref="search_d">
      <el-row type="flex" class="row-bg" >
        <!-- <el-col :span="18">
          <el-button type="primary" @click="newBtn">新建</el-button>
        </el-col> -->
        <el-col :span="6">
          <el-input placeholder="请输入收货人/用户昵称" v-model="queryInfo.condition.queryKey" class="input-with-select" clearable>
            <el-button slot="append" icon="el-icon-search" @click="getList"></el-button>
          </el-input>
        </el-col>
      </el-row>
    </div>

    <!-- 表格部分 -->
    <div>
      <el-table border ref="table" :data="tableData" tooltip-effect="dark" style="width: 100%"
      height="calc(100vh - 220px)">
          <el-table-column label="流水号（支付发起单号）" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.transNo }}</template>
          </el-table-column>

          <el-table-column label="商家名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.merchantName }}</template>
          </el-table-column>
          <el-table-column label="商家logo" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.merchantLogo }}</template>
          </el-table-column>
          <el-table-column label="用户昵称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.memberName }}</template>
          </el-table-column>
          <el-table-column label="用户头像" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-image :src="scope.row.memberLogo" :preview-src-list="[scope.row.memberLogo]"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="用户手机号" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.memberPhone }}</template>
          </el-table-column>
          <el-table-column label="商品数量" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.productNum }}</template>
          </el-table-column>
          <el-table-column label="订单金额" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.orderAmount }}</template>
          </el-table-column>
          <el-table-column label="收货人名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.consigneeName }}</template>
          </el-table-column>
          <el-table-column label="收货人电话" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.consigneePhone }}</template>
          </el-table-column>
          <el-table-column label="收货人地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.consigneeAddress }}</template>
          </el-table-column>
          <el-table-column label="发货人" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.consignor }}</template>
          </el-table-column>
          <el-table-column label="发货时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.courierDate }}</template>
          </el-table-column>
          <el-table-column label="快递单号" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.courierCode }}</template>
          </el-table-column>
          <el-table-column label="物流公司名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.logisticsCompany }}</template>
          </el-table-column>

          <el-table-column label="支付方式" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.payType }}</template>
          </el-table-column>
          <el-table-column label="订单状态" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{  orderStatus[scope.row.orderStatus] }}</template>
          </el-table-column>
          <el-table-column label="支付状态" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.payStatus=='0'? '待支付':'已支付' }}</template>
          </el-table-column>
          <el-table-column label="支付时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.paidDate }}</template>
          </el-table-column>
          <el-table-column label="小计" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.sumToPay }}</template>
          </el-table-column>
          <el-table-column label="已支付金额" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.sumPaid }}</template>
          </el-table-column>
          <el-table-column label="确认收货时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.confirmOrderDate }}</template>
          </el-table-column>
          <el-table-column label="确认收货人" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.confirmOrderBy }}</template>
          </el-table-column>
          <el-table-column label="微信订单编号" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.wechatOrderCode }}</template>
          </el-table-column>
          <el-table-column label="微信退款单号" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.refundNo }}</template>
          </el-table-column>
          <el-table-column label="微信退款回调时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.refundDate }}</template>
          </el-table-column>
          <el-table-column label="退货原因" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.reasonForReturn }}</template>
          </el-table-column>
          <el-table-column label="创建时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.createDate }}</template>
          </el-table-column>
          <el-table-column label="订单备注" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.remark }}</template>
          </el-table-column>
          <el-table-column label="运费" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.freight }}</template>
          </el-table-column>
          <el-table-column label="发货地址" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.deliveryAddress }}</template>
          </el-table-column>
          <el-table-column label="修改时间" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.updateDate }}</template>
          </el-table-column>
          <el-table-column label="是否申请退款" align="center" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.row.isRefund==0?'否' :'是' }}
            </template>
          </el-table-column>
          <el-table-column label="" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.systemId }}</template>
          </el-table-column>
        <el-table-column label="操作" fixed="right" width="135" align="center">
          <template slot-scope="scope">
            <!-- <span>  </span> -->
            <el-button type="text"  v-if="scope.row.orderStatus==1 && type==1" @click="sendProduct(scope.row)" size="small">去发货</el-button>
            <el-button style="margin-left:0;color:#f59a23" type="text" @click="showDetails(scope.row)" size="small">明细</el-button>
            <!-- <el-button type="text" @click="edit(scope.row)" size="small">编辑</el-button> -->
            <el-popconfirm title="确定删除吗？" @confirm="handleDelte(scope.row.id)">
              <el-button style="color: red;" slot="reference" type="text">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="block">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.currPage" :page-sizes="[100, 200, 300, 400]" :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="(labelType=='add'?'新增':'编辑')" :visible.sync="showDialog" width="800px">
      <el-form ref="form" :model="form" :rules="rules" label-width="120px">
        <el-row :gutter="10">


            <el-col :span="12">
              <el-form-item label="流水号（支付发起单号）：" prop="siteName">
                <el-input v-model="form.transNo" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="商家名称：" prop="siteName">
                <el-input v-model="form.merchantName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商家logo：" prop="siteName">
                <el-input v-model="form.merchantLogo" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="用户昵称：" prop="siteName">
                <el-input v-model="form.memberName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户头像：" prop="siteName">
                <el-input v-model="form.memberLogo" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户手机号：" prop="siteName">
                <el-input v-model="form.memberPhone" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="商品数量：" prop="siteName">
                <el-input v-model="form.productNum" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="订单金额：" prop="siteName">
                <el-input v-model="form.orderAmount" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货人名称：" prop="siteName">
                <el-input v-model="form.consigneeName" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货人电话：" prop="siteName">
                <el-input v-model="form.consigneePhone" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="收货人地址：" prop="siteName">
                <el-input v-model="form.consigneeAddress" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发货人：" prop="siteName">
                <el-input v-model="form.consignor" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发货时间：" prop="siteName">
                <el-input v-model="form.courierDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="快递单号：" prop="siteName">
                <el-input v-model="form.courierCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="物流公司名称：" prop="siteName">
                <el-input v-model="form.logisticsCompany" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item label="支付方式：" prop="siteName">
                <el-input v-model="form.payType" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="订单状态：" prop="siteName">
                <el-input v-model="form.orderStatus" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付状态：" prop="siteName">
                <el-input v-model="form.payStatus" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="支付时间：" prop="siteName">
                <el-input v-model="form.paidDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="小计：" prop="siteName">
                <el-input v-model="form.sumToPay" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="已支付金额：" prop="siteName">
                <el-input v-model="form.sumPaid" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认收货时间：" prop="siteName">
                <el-input v-model="form.confirmOrderDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="确认收货人：" prop="siteName">
                <el-input v-model="form.confirmOrderBy" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信订单编号：" prop="siteName">
                <el-input v-model="form.wechatOrderCode" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信退款单号：" prop="siteName">
                <el-input v-model="form.refundNo" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信退款回调时间：" prop="siteName">
                <el-input v-model="form.refundDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="退货原因：" prop="siteName">
                <el-input v-model="form.reasonForReturn" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="创建时间：" prop="siteName">
                <el-input v-model="form.createDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="订单备注：" prop="siteName">
                <el-input v-model="form.remark" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="运费：" prop="siteName">
                <el-input v-model="form.freight" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="发货地址：" prop="siteName">
                <el-input v-model="form.deliveryAddress" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="修改时间：" prop="siteName">
                <el-input v-model="form.updateDate" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="是否申请退款(0否：" prop="siteName">
                <el-input v-model="form.isRefund" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="：" prop="siteName">
                <el-input v-model="form.systemId" placeholder="请输入名称"></el-input>
              </el-form-item>
            </el-col>
        </el-row>
      </el-form>
      <span slot="footer">
        <el-button size="small" @click="showDialog = false">取消</el-button>
        <el-button size="small" type="primary" @click="handleSave">确定</el-button>
      </span>
    </el-dialog>
    <!--  -->
    <el-dialog
  :visible.sync="dialogDetail"
  width="60%">
  <template slot="title">
    <div style="border-bottom:1px solid #f2f2f2">订单详情"</div>
  </template>
  <el-table border  :data="detailTable" tooltip-effect="dark" style="width: 100%"
      height="55vh">
      <el-table-column label="序号" align="center" type="index">

          </el-table-column>
          <el-table-column label="流水号" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.transNo }}</template>
          </el-table-column>

          <el-table-column label="商品名称" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.productName }}</template>
          </el-table-column>
          <el-table-column label="商品类别" align="center" show-overflow-tooltip>
            <template slot-scope="scope">{{ scope.row.merchantCatagoryName }}</template>
          </el-table-column>
          <el-table-column label="商品描述" prop="productSpecification" align="center"
          show-overflow-tooltip>
        </el-table-column>
          <el-table-column label="商品数量" prop="productNum" align="center">
          </el-table-column>
          <el-table-column label="商品价格" prop="price" align="center" >

          </el-table-column>
      </el-table>
  <span slot="footer" class="dialog-footer">
    <!-- <el-button @click="dialogDetail = false">取 消</el-button> -->
    <el-button type="primary" @click="dialogDetail = false">关 闭</el-button>
  </span>
</el-dialog>
<el-dialog
  title="发货"
  :visible.sync="dialogVisibleSend"
  width="420px">
  <el-row>
    <el-form label-width="90px">
      <el-col :span="24">
        <el-form-item label="发货人:">
          <el-input placeholder="请输入发货人" v-model="sendForm.consignor">

          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="快递公司:">
          <el-input placeholder="请输入快递公司名称" v-model="sendForm.logisticsCompany">

          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="快递单号:">
          <el-input placeholder="请输入快递单号" v-model="sendForm.courierCode">

          </el-input>
        </el-form-item>
      </el-col>
      </el-form>
  </el-row>
  <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisibleSend = false">取 消</el-button>
    <el-button type="primary" @click="dialogVisibleSendBtn">确 定</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      type:JSON.parse(localStorage.getItem('userInfo')).userInfo.type,
      dialogVisibleSend:false,
      orderStatus:['待支付','待发货','已发货','已完成','退款中','已退款'],
      detailTable:[],
      dialogDetail:false,
      labelType: 'add',
      rules: {},
      total: 0,
      queryInfo: {
        pageSize: 100,
        pageCount: 1,
        currPage: 1,
        condition: {
          merchantCode:JSON.parse(localStorage.getItem('userInfo')).userInfo.objectCode
        }
      },
      form: {},
      showDialog: false,
      tableData: [],
      sendForm:{
        id:"",
         consignor:"",
          courierCode:"" ,
          logisticsCompany:""
      },
    };
  },

  created () {
    this.getList()
  },



  methods: {
   async dialogVisibleSendBtn(){
    let res=await this.$http.post('order/delivery',this.sendForm)
    if(res.data.code==200){
      this.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
        this.dialogVisibleSend=false
        this.getList()
    }else{
      this.$message.error(res.data.message)
    }
    },
    sendProduct(row){
      this.sendForm.id=row.id
      this.sendForm.consignor=""
      this.sendForm.courierCode=""
      this.sendForm.logisticsCompany=""
      this.dialogVisibleSend=true
    },
  async  showDetails(row){
    this.dialogDetail=true
    let res=await this.$http.post('orderItem/queryList',{orderNumber:row.orderNumber})
    console.log(res,'details')
    if(res.data.code==200){
      this.detailTable=res.data.data
    }else{
      this.$message.error(res.data.message)
    }
    },

    //获取数据
    getList () {
	  var that = this;
	  that.$http.post("/order/list", that.queryInfo).then(function (response) {
        console.log(response.data.data);
        if (response.data.code == 200) {
          that.total = response.data.data.count;
          that.queryInfo.currPage=response.data.data.currPage
          that.tableData = response.data.data.data;
        }
      });
    },

    // 保存
    handleSave () {
	    var that = this;
	  that.$http.post("/order/save", that.form).then(function (response) {
		  if (response.data.code == 200) {
			  that.$notify.success({
				title: "提示",
				message: "保存成功",
				showClose: true,
			  });
			 that.showDialog = false
			  that.getList()
		  }else{
			  that.$notify.info({
				title: "提示",
				message: response.data.message,
				showClose: true,
			  });
		  }
      }).catch(function(error){


      })


    },

    //修改
    edit (row) {
      this.showDialog = true;
      this.labelType = 'edit'
      this.form = JSON.parse(JSON.stringify(row))
    },

    // 删除当前行
//    handleDelte (id) {
//      del({ id: id }).then(res => {
//        if (res.code == 200) {
 //         this.$message.success('删除成功')
 //         this.getList()
 //       }
//      })
//    },

	// 删除用户
    async handleDelte(id) {
      const confirmResult = await this.$confirm("确定删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (confirmResult === "confirm") {
           var that = this;
        that.$http.post("/order/delete" ,{"id":id}).then(function (response) {
			if(response.data.code == 200){
			 that.$notify.success({
				title: "提示",
				message: "删除成功",
				showClose: true,
			  });
			  that.getList();
			}else{
				 that.$notify.info({
					title: "提示",
					message: response.data.message,
					showClose: true,
				  });
			}

        });
      }
    },

    //新增按钮
    newBtn () {
      this.labelType = 'add'
      this.showDialog = true;
      this.form = {}
    },

    // 修改页数大小
    handleSizeChange (val) {
      this.queryInfo.pageSize = val;
      this.getList()
    },

    // 获取当前页面
    handleCurrentChange (val) {
      this.queryInfo.currPage = val;
      this.getList()
    },

  },
};
</script>

<style lang="scss" scoped>
/deep/.el-table__body-wrapper::-webkit-scrollbar-thumb{
 background:#409EFF;
 cursor: pointer;
}
/deep/.el-table__fixed{
  height: calc(100% - 8px) !important;
}
/deep/.el-table__fixed-right{
  height: calc(100% - 8px) !important;
}
</style>

